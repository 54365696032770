body, p, p > a {
  font-family: 'Roboto Mono', monospace;
}

a, span{
  font-family: 'Bangers', cursive;
}
a {
  opacity: 0.75;
  text-decoration: none;
  color: black;
}
a.active, a:hover{
  opacity: 1;
}
header a{
   color: white;
}

code{
  font-family: 'Press Start 2P', cursive;
  color: white;
  background-color: grey;
}
h1 {
  font-family: 'Orbitron', sans-serif;
}
h2{
  font-family: 'Roboto Mono', monospace;
}
h3{
  font-family: 'Anton', sans-serif;
}
h4{
  font-family: 'Permanent Marker', cursive;

}
h5{
  font-family: 'Orbitron', sans-serif;
}

h1 a {
  font-family: 'Monoton', cursive;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

main {
  margin: 0 2rem;
  text-align: center;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 2fr auto;
  grid-template-areas:
  "header"
  "main"
}

header {
  grid-area: header;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    "logo nav";
  padding: 1rem;
  background-color: #2B2B2B;
  color: white;
}

header h1{
  grid-area: logo;
  margin: 0;
  text-align: left;
  font-size: 3rem;
}
nav{
  grid-area: nav;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  vertical-align: middle;
  align-items: center;
  cursor: pointer;
}

nav a{
  font-size: 1.5rem;
}

nav ul{
 list-style-type: none;
 display: inline-flex;
}

nav ul li {
  margin: 0 1rem;
}

article{
  margin: 2rem auto;
  max-width: 50rem;
}
article footer a{
  margin: 0 0.5rem;
}

summary{
  max-height: 15rem;
  overflow: hidden;
  cursor: pointer;
}

.profile {
  pointer-events: none;
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 100%;
}

@media screen and (max-width: 42.5rem) {

header h1{
  font-size: 1rem;
}
nav a{
  font-size: 1rem;
}
}